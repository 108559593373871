import React from 'react';
import "./Services.css"

const SideBySideReverse = () => {
  return (
    <section id="sbsr-1599">
      <div className="cs-container">
        <div className="cs-content">
          <span className="cs-topper">Get Into Flying</span>
          <h2 className="cs-title">All The Fun Begins With The First Trial Flight</h2>
          <p className="cs-text">
          Experience the thrill of flight with Learn to Fly. Embark on a trial flight that immerses you in the excitement of aviation, giving you the chance to take control and feel the rush of piloting an aircraft. Our expert instructor will guide you through the basics, ensuring a safe, exhilarating introduction to flying that will stay with you long after you touch back down. Whether you’re fulfilling a lifelong dream or exploring a new passion, Learn to Fly provides an unforgettable experience in the skies.
            </p>
          <picture className="cs-picture cs-picture1">
            <source
              media="(max-width: 600px)"
              srcSet="./planeoverwater.jpg"
            />
            <source
              media="(min-width: 601px)"
              srcSet="./planeoverwater.jpg"
            />
            <img
              loading="lazy"
              decoding="async"
              src="./planeoverwater.jpg"
              alt="people"
              width="608"
              height="406"
            />
          </picture>
        </div>
        <div className="cs-stats-group">
          <picture className="cs-picture cs-picture2">
            <source
              media="(max-width: 600px)"
              srcSet="./cessna.avif"
            />
            <source
              media="(min-width: 601px)"
              srcSet="./cessna.avif"
            />
            <img
              loading="lazy"
              decoding="async"
              src="./cessna.avif"
              alt="model"
              width="608"
              height="662"
            />
          </picture>
          <ul className="cs-stats">
            <li className="cs-stat">
              <span className="cs-number">100%</span>
              <span className="cs-desc">Guarenteed Fun</span>
            </li>
            <li className="cs-stat">
              <span className="cs-number">All Year</span>
              <span className="cs-desc">We operate year round</span>
            </li>
            <li className="cs-stat">
              <span className="cs-number">Top-Rated</span>
              <span className="cs-desc">By Previous Customers</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SideBySideReverse;
