import React from 'react';
import "./Contact.css"

const ContactForm = () => {
    return (
        <section id="cs-contact-242">
            <div className="cs-container">
                <div className="cs-content">
                    <span className="cs-topper">Here To Help</span>
                    <h2 className="cs-title">Contact Us</h2>
                    {/* <p className="cs-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque enim risus lorem diam. Viverra egestas penatibus feugiat vitae in dignissim.
                    </p> */}
                </div>
                {/* Form */}
                <form id="cs-form-242" name="Contact Form" method="post">
                    <label className="cs-label">
                        Name
                        <input className="cs-input" required type="text" id="name-242" name="name" placeholder="John/Jane Doe" />
                    </label>
                    <label className="cs-label">
                        Email
                        <input className="cs-input" required type="email" id="email-242" name="email" placeholder="name@company.com" />
                    </label>
                    <label className="cs-label">
                        Phone
                        <input className="cs-input" required type="tel" id="phone-242" name="phone" placeholder="022 987 6543" />
                    </label>
                    <label className="cs-label cs-label-message">
                        Message
                        <textarea className="cs-input cs-textarea" required name="Message" id="message-242" placeholder="Hello, I’m interested in a trial flight..."></textarea>
                    </label>
                    <button className="cs-button-solid cs-submit" type="submit">Submit Message Now</button>
                </form>
                {/* Contact Info */}
                <div className="cs-right-section">
                    <ul className="cs-ul">
                        <li className="cs-li">
                            <picture className="cs-icon-wrapper">
                                <img
                                    aria-hidden="true"
                                    src="https://csimg.nyc3.digitaloceanspaces.com/Contact-Page/mail.svg"
                                    alt="mail icon"
                                    className="cs-icon"
                                    width="36"
                                    height="36"
                                    decoding="async"
                                />
                            </picture>
                            <div className="cs-flex-group-contact">
                                <span className="cs-header">Email</span>
                                <a href="mailto:justindalkjaer@gmail.com" className="cs-link">justindalkjaer@gmail.com</a>
                            </div>
                        </li>
                        <li className="cs-li">
                            <picture className="cs-icon-wrapper">
                                <img
                                    aria-hidden="true"
                                    src="https://csimg.nyc3.digitaloceanspaces.com/Contact-Page/phone.svg"
                                    alt="phone icon"
                                    className="cs-icon"
                                    width="36"
                                    height="36"
                                    decoding="async"
                                />
                            </picture>
                            <div className="cs-flex-group-contact">
                                <span className="cs-header">Phone</span>
                                <a href="tel:022 051 7898" className="cs-link">022 051 7898</a>
                            </div>
                        </li>
                        <li className="cs-li">
                            <picture className="cs-icon-wrapper">
                                <img
                                    aria-hidden="true"
                                    src="https://csimg.nyc3.digitaloceanspaces.com/Contact-Page/pin.svg"
                                    alt="address icon"
                                    className="cs-icon"
                                    width="36"
                                    height="36"
                                    decoding="async"
                                />
                            </picture>
                            <div className="cs-flex-group-contact">
                                <span className="cs-header">Address</span>
                                <a href="https://www.google.com/maps/dir//Whangarei+Airport+(WRE)+Handforth+Street,+Onerahi,+Whang%C4%81rei+0110/@-35.7684752,174.3614708,17z/data=!4m12!1m2!2m1!1sonerahi+airport+!4m8!1m0!1m5!1m1!1s0x6d0b7de59f126e71:0xf00ef62249b7330!2m2!1d174.3632498!2d-35.7696861!3e3?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D" className="cs-link">
                                    <span className="cs-block">Handforth Street, Onerahi, Whangārei 0110 </span>
                                </a>
                            </div>
                        </li>
                    </ul>
                    {/* Picture */}
                    <picture className="cs-picture">
                        <source media="(max-width: 600px)" srcSet="./image.png" />
                        <source media="(min-width: 601px)" srcSet="./image.png" />
                        <img
                            className="cs-img"
                            aria-hidden="true"
                            loading="lazy"
                            decoding="async"
                            src="./image.png"
                            alt="google map"
                            width="462"
                            height="438"
                        />
                    </picture>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
