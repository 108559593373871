import React, { useState, useEffect } from 'react';
import "./Navigation.css"

const Header = () => {
  const [isNavActive, setIsNavActive] = useState(false);
  const [isAriaExpanded, setIsAriaExpanded] = useState(false);

  // Toggle mobile navigation and aria-expanded
  const handleMenuToggle = () => {
    setIsNavActive(!isNavActive);
    setIsAriaExpanded(!isAriaExpanded);
    document.body.classList.toggle('cs-open');
  };

  // Function to toggle dropdowns
  const handleDropdownToggle = (e) => {
    // e.currentTarget.classList.toggle('cs-active');
  };

  useEffect(() => {
    const dropDowns = Array.from(document.querySelectorAll('#cs-navigation .cs-dropdown'));
    dropDowns.forEach((item) => {
      item.addEventListener('click', handleDropdownToggle);
    });
    return () => {
      dropDowns.forEach((item) => {
        item.removeEventListener('click', handleDropdownToggle);
      });
    };
  }, []);

  return (
    <header id="cs-navigation" className={isNavActive ? 'cs-active' : ''}>
      <div className="cs-container">
        {/* Nav Logo */}
        <a href="/" className="cs-logo" aria-label="back to home">
            LEARN TO FLY
        </a>
        
        {/* Navigation List */}
        <nav className="cs-nav" role="navigation">
          {/* Mobile Nav Toggle */}
          <button
            className={`cs-toggle ${isNavActive ? 'cs-active' : ''}`}
            aria-label="mobile menu toggle"
            onClick={handleMenuToggle}
          >
            <div className="cs-box" aria-hidden="true">
              <span className="cs-line cs-line1" aria-hidden="true"></span>
              <span className="cs-line cs-line2" aria-hidden="true"></span>
              <span className="cs-line cs-line3" aria-hidden="true"></span>
            </div>
          </button>
          
          {/* Wrapper for Nav List */}
          <div className="cs-ul-wrapper">
            <ul
              id="cs-expanded"
              className="cs-ul"
              aria-expanded={isAriaExpanded ? 'true' : 'false'}
            >
              <li className="cs-li">
                <a href="/#sbsr-1599" className="cs-li-link">Where do you start</a>
              </li>
              <li className="cs-li">
                <a href="/#pricing-1087" className="cs-li-link">Pricing</a>
              </li>
            </ul>
          </div>
        </nav>

        {/* Contact Button */}
        <a href="/contact" className="cs-button-solid cs-nav-button">BOOK TRIAL FLIGHT</a>
        
        {/* Dark Mode Toggle (optional, uncomment to use) */}
        {/* <button id="dark-mode-toggle" aria-label="dark mode toggle">
          <svg
            className="cs-moon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 480 480"
            style={{ enableBackground: 'new 0 0 480 480' }}
            xmlSpace="preserve"
          >
            <path d="M459.782 347.328c-4.288-5.28-11.488-7.232-17.824-4.96-17.76 6.368-37.024 9.632-57.312 9.632-97.056 0-176-78.976-176-176 0-58.4 28.832-112.768 77.12-145.472 5.472-3.712 8.096-10.4 6.624-16.832S285.638 2.4 279.078 1.44C271.59.352 264.134 0 256.646 0c-132.352 0-240 107.648-240 240s107.648 240 240 240c84 0 160.416-42.688 204.352-114.176 3.552-5.792 3.04-13.184-1.216-18.496z" />
          </svg>
          <img
            className="cs-sun"
            aria-hidden="true"
            src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Fsun.svg"
            decoding="async"
            alt="moon"
            width="15"
            height="15"
          />
        </button> */}
      </div>
    </header>
  );
};

export default Header;
