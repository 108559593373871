import React from 'react';
import "./Pricing.css"

const Pricing = () => {
    const pricingData = [
        {
            package: "Personal",
            price: "$49.99",
            description: "Basic features for up to 10 services",
            features: [
                "All available starter feature",
                "Home service 24/7",
                "Customer agent service",
                "Professional Service",
                "2 dedicated mechanic",
                "Remote Service mechanic"
            ],
            disabledFeatures: [4, 5]
        },
        {
            package: "Trail Flight",
            price: "$99.99",
            description: "Basic features for up to 10 services",
            features: [
                "All available starter feature",
                "Home service 24/7",
                "Customer agent service",
                "Professional Service",
                "2 dedicated mechanic",
                "Remote Service mechanic"
            ],
            disabledFeatures: [4, 5],
            popular: true
        },
        {
            package: "Basic",
            price: "$49.99",
            description: "Basic features for up to 10 services",
            features: [
                "All available starter feature",
                "Home service 24/7",
                "Customer agent service",
                "Professional Service",
                "2 dedicated mechanic",
                "Remote Service mechanic"
            ],
            disabledFeatures: [4, 5]
        }
    ];

    return (
        <section id="pricing-1087">
            <div className="cs-container">
                <div className="cs-content">
                    <span className="cs-topper">Pricing</span>
                    <h2 className="cs-title">Simple & Transparent Pricing</h2>
                    <p className="cs-text">
                        We are one of the leading auto repair shops serving customers in Tucson. All mechanic services are performed by highly qualified mechanics. We handle all makes and models in more than 40 car brands.
                    </p>
                </div>
                <ul className="cs-card-group">
                    {pricingData.map((plan, index) => (
                        <li key={index} className={`cs-item ${plan.popular ? 'cs-popular' : ''}`}>
                            <span className="cs-package">{plan.package}</span>
                            <span className="cs-price">{plan.price}</span>
                            <p className="cs-item-p">{plan.description}</p>
                            <a href="/contact" className="cs-button-solid cs-price-button">Book Appointment</a>
                            <ul className="cs-ul">
                                {plan.features.map((feature, i) => (
                                    <li key={i} className={`cs-li ${plan.disabledFeatures.includes(i) ? 'cs-disabled' : ''}`}>
                                        {feature}
                                        <img
                                            className="cs-icon"
                                            aria-hidden="true"
                                            loading="lazy"
                                            decoding="async"
                                            src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/mech-check.svg"
                                            alt="checkmark"
                                            width="18"
                                            height="18"
                                        />
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
            <img
                className="cs-floater cs-floater1"
                aria-hidden="true"
                loading="lazy"
                decoding="async"
                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/left-chevron.svg"
                alt="left chevron"
                width="121"
                height="272"
            />
            <img
                className="cs-floater cs-floater2"
                aria-hidden="true"
                loading="lazy"
                decoding="async"
                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/right-chevron.svg"
                alt="right chevron"
                width="121"
                height="272"
            />
        </section>
    );
};

export default Pricing;
