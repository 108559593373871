import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from "./componants/Navigation";
import Hero from "./componants/Hero";
import Services from "./componants/Services";
import Pricing from './componants/Pricing';
import Contact from './componants/Contact'; // Assuming there's a Contact component
import Footer from './componants/Footer';
import "./App.css"

function Home() {
  return (
    <>
      <Navigation />
      <Hero />
      <Services />
      <Pricing />
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={
          <>
            <Navigation />
            <Contact />
            <Footer />
          </>
        } />
      </Routes>
    </Router>
  );
}

export default App;
