import React from 'react';
import "./Hero.css"

const Hero = () => {
  return (
    <section id="hero-229">
      <div className="cs-container">
        <div className="cs-flex-group">
          <span className="cs-topper">want to learn to fly in whangarei?</span>
          <h1 className="cs-title">Learn To Fly Today.</h1>
          <p className="cs-text">
          From The First Time You Step In The Plane, To The First Time You Land Solo On Great Barrier - We'll Get You There          </p>
        </div>
        <div className="cs-email-group">
            <input
              type="email"
              placeholder="Enter your email"
              className="cs-email-input"
              aria-label="Enter your email"
            />
            <button className="cs-button-submit">BOOK A TRAIL FLIGHT TODAY</button>
          </div>

      </div>

      {/* Background Image */}
      <picture className="cs-picture">
        <source
          media="(max-width: 600px)"
          srcSet="https://cessna.txtav.com/-/media/cessna/images/aircraft/turboprop/caravan/caravan-208-hero.ashx"
        />
        <source
          media="(min-width: 601px)"
          srcSet="https://cessna.txtav.com/-/media/cessna/images/aircraft/turboprop/caravan/caravan-208-hero.ashx"
        />
        <img
          loading="lazy"
          decoding="async"
          src="https://cessna.txtav.com/-/media/cessna/images/aircraft/turboprop/caravan/caravan-208-hero.ashx"
          alt="mountains"
          width="2250"
          height="1500"
          aria-hidden="true"
        />
      </picture>
    </section>
  );
};

export default Hero;
